import React from "react";
import { useLocation } from "react-router-dom";
import CookieBar from "../CookieBar/CookieBar";
import FooterLogoBox from "./FooterLogoBox/FooterLogoBox";
import facebook from "../../assets/images/redesign-v2/socials/facebook.svg";
import twitter from "../../assets/images/redesign-v2/socials/twitter.svg";
import instagram from "../../assets/images/redesign-v2/socials/instagram.svg";
import tiktok from "../../assets/images/redesign-v2/socials/tiktok.svg";
import fundraising from "../../assets/images/footer/logo-fundraising-regulator.png";
import "./styles.css";

function Footer(props) {
  const location = useLocation();
  const [isLoadingPage, setIsLoadingPage] = React.useState(true);
  const currentYear = new Date().getFullYear();
  let isHide = true;
  const { pathname } = location;
  let payPageCheck = /pay/;
  const signIn = /basket/;
  const profile = /profile/;

  if (signIn.test(pathname) || profile.test(pathname)) {
    isHide = false;
  }
  let isPayPage = payPageCheck.test(pathname);

  const onClickBegambleaware = (e) => {
    e.preventDefault();
    window.open("https://www.begambleaware.org/", "BegambleAware", "_blank");
  };

  React.useEffect(() => {
    setIsLoadingPage((prev) => !prev);
  }, []);
  return (
    <React.Fragment>
      {props.insideComponent || !isHide ? (
        <React.Fragment>
          {!isLoadingPage && <CookieBar />}
          <div className={`footerWrapper-bg footerWrapper-bg--green`}>
            <div className="footer-image-container">
              <div className="footer-image">
                <FooterLogoBox widget={props.widget} />
              </div>
            </div>
            <div className="footer__logos">
              <a
                className="footer-fundraisingregulator"
                href="https://www.fundraisingregulator.org.uk/directory/raffle-house-ltd"
                rel="nofollow"
                target="_blank"
                aria-label="fundraising regulator"
              >
                <img src={fundraising} alt="fundraising regulator" />
              </a>
              <a
                className="footer-begambleaware"
                href="https://www.begambleaware.org/"
                onClick={onClickBegambleaware}
                aria-label="begambleaware"
              >
                <svg
                  width="210"
                  height="30"
                  viewBox="0 0 2715 306"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M85.6254 105.126H51.9496V150.157H88.236C103.899 150.157 114.602 140.414 114.602 126.457C114.602 114.08 102.594 105.126 85.6254 105.126ZM86.9307 179.388H51.9496V230.212H90.5855C107.032 230.212 119.823 219.415 119.823 205.195C119.823 189.658 106.771 179.388 86.9307 179.388ZM0.522106 105.126V74.8422H70.7454C102.072 74.8422 113.819 75.8956 122.695 79.8457C134.965 85.1124 150.889 100.123 150.889 124.613C150.889 143.31 144.885 152.527 127.133 162.271C148.017 170.961 157.676 184.128 157.676 208.618C157.676 239.692 133.92 260.496 98.4171 260.496H0V230.212H14.3579V105.126H0.522106Z"
                    fill="white"
                  />
                  <path
                    d="M216.672 172.541H277.498C275.148 154.634 264.445 144.89 247.998 144.89C232.857 144.89 221.11 155.161 216.672 172.541ZM276.975 214.148H313.262C306.997 244.696 281.674 263.656 247.737 263.656C208.579 263.656 179.864 232.845 179.864 189.658C179.864 146.997 208.841 115.396 247.998 115.396C270.188 115.396 289.767 125.93 301.775 144.1C314.567 163.851 315.089 182.548 315.611 196.768H214.845C218.238 233.372 241.994 235.216 248.521 235.216C262.356 235.216 271.493 228.632 276.975 214.148Z"
                    fill="white"
                  />
                  <path
                    d="M431.26 186.498V168.327H494.957V260.496H474.073V231.792C468.329 241.272 465.719 244.696 460.498 249.172C448.489 259.706 433.87 265.236 418.207 265.236C375.395 265.236 341.98 223.102 341.98 168.854C341.98 114.08 375.133 71.4188 417.685 71.4188C435.176 71.4188 455.799 76.4222 472.506 103.546V74.8422H491.824V133.83H471.723C469.113 123.56 465.98 116.976 461.281 111.183C450.839 98.2794 436.742 90.9059 422.123 90.9059C399.411 90.9059 366.78 110.393 366.78 167.801C366.78 212.305 390.536 245.486 422.384 245.486C445.618 245.486 467.546 226.262 472.767 201.508C473.551 197.821 473.812 194.135 474.073 186.498H431.26Z"
                    fill="white"
                  />
                  <path
                    d="M621.309 210.988V188.078L608.517 189.658C565.704 194.925 551.346 202.825 551.346 220.732C551.346 235.479 564.138 247.592 580.323 247.592C605.123 247.592 621.309 223.892 621.309 210.988ZM661.25 243.906V260.496H621.57V235.479C610.344 253.123 595.203 263.129 574.58 263.129C548.475 263.129 529.418 244.959 529.418 220.469C529.418 193.608 552.913 180.178 608.256 174.911C610.083 174.648 615.827 174.121 621.309 173.594V159.111C621.309 143.047 609.039 133.04 589.721 133.04C569.359 133.04 560.222 139.887 556.045 158.057H534.9C538.033 143.837 542.21 136.463 550.563 129.09C559.961 121.19 573.014 116.976 588.155 116.976C616.871 116.976 641.41 130.407 641.41 165.957V243.906H661.25Z"
                    fill="white"
                  />
                  <path
                    d="M715.747 118.556V145.417C722.012 135.673 734.542 116.186 760.126 116.186C778.66 116.186 789.364 125.93 792.757 145.68C798.239 136.2 800.85 132.777 806.071 128.3C815.208 120.136 825.128 116.186 836.092 116.186C857.499 116.186 870.029 130.143 870.029 154.634V243.906H889.347V260.496H849.928V162.007C849.928 142.257 843.402 133.303 829.827 133.303C805.549 133.303 792.757 162.797 792.757 172.278V243.906H812.858V260.496H772.656V158.847C772.656 141.204 766.13 133.303 752.555 133.303C730.888 133.303 715.486 160.427 715.486 173.331V243.906H735.326V260.496H675.022V243.906H695.385V135.41H675.022V118.556H715.747Z"
                    fill="white"
                  />
                  <path
                    d="M949.45 177.018V200.718C949.45 224.155 968.246 244.959 989.653 244.959C1013.67 244.959 1029.07 223.102 1029.07 189.131C1029.07 156.477 1013.67 134.093 990.436 134.093C970.074 134.093 949.45 157.004 949.45 177.018ZM910.292 83.2691V66.6787H949.45V148.577C954.672 138.57 970.857 117.24 995.396 117.24C1026.98 117.24 1051 149.63 1051 191.238C1051 229.949 1025.42 262.339 994.874 262.339C970.074 262.339 958.065 245.749 949.45 233.899V260.496H909.509V243.906H929.349V83.2691H910.292Z"
                    fill="white"
                  />
                  <path
                    d="M1067.11 83.2691V66.6787H1107.06V243.906H1125.85V260.496H1066.85V243.906H1086.95V83.2691H1067.11Z"
                    fill="white"
                  />
                  <path
                    d="M1171.66 178.071H1248.67C1247.11 160.427 1243.19 150.684 1235.1 142.784C1228.83 136.99 1221 134.093 1211.86 134.093C1194.9 134.093 1180.8 145.68 1174.53 164.377C1173.49 167.801 1172.18 173.068 1171.66 178.071ZM1246.06 221.522H1268.77C1265.9 231.265 1262.77 237.059 1257.55 243.116C1246.32 256.283 1230.92 263.393 1213.43 263.393C1175.32 263.393 1148.69 233.372 1148.69 190.185C1148.69 148.577 1175.32 116.713 1210.04 116.713C1245.02 116.713 1271.91 144.1 1271.91 193.608H1170.36C1172.18 214.412 1176.88 225.999 1186.54 235.479C1193.85 242.589 1202.47 246.012 1212.91 246.012C1229.09 246.012 1239.01 238.639 1246.06 221.522Z"
                    fill="white"
                  />
                  <path
                    d="M1406.02 191.765L1382.01 109.603L1357.47 191.765H1406.02ZM1308.65 230.212L1359.82 74.8422H1403.94L1454.58 230.212H1468.42V260.496H1426.13L1414.12 220.205H1349.38L1337.37 260.496H1294.55V230.212H1308.65Z"
                    fill="white"
                  />
                  <path
                    d="M1467.23 146.997V119.61H1523.09V146.997H1508.99L1528.31 229.949L1551.28 119.61H1587.31L1610.54 228.632L1629.08 146.997H1615.5V119.61H1671.63V146.997H1659.62L1629.6 260.496H1592.53L1569.3 162.534L1547.37 260.496H1509.78L1478.97 146.997H1467.23Z"
                    fill="white"
                  />
                  <path
                    d="M1778.52 207.565V193.871C1745.63 197.031 1724.23 199.928 1724.23 219.415C1724.23 231.002 1733.36 238.639 1747.2 238.639C1764.95 238.639 1778.52 225.209 1778.52 207.565ZM1811.16 165.957V233.109H1823.16V260.496H1779.83V243.906C1765.47 258.389 1754.77 263.393 1737.54 263.393C1709.61 263.393 1688.2 244.432 1688.2 219.415C1688.2 178.071 1734.41 173.594 1779.05 169.381C1778.79 147.787 1773.56 142.257 1753.2 142.257C1738.84 142.257 1728.92 150.42 1728.14 163.587L1694.2 162.534C1696.03 134.357 1718.48 116.186 1751.64 116.186C1790.27 116.186 1811.16 133.83 1811.16 165.957Z"
                    fill="white"
                  />
                  <path
                    d="M1892.54 119.61V141.467C1901.68 123.56 1911.86 116.186 1927.52 116.186C1932.22 116.186 1936.4 116.713 1943.18 118.03V150.947C1936.92 149.63 1931.96 149.104 1926.22 149.104C1907.94 149.104 1892.54 162.271 1892.54 178.334V233.109H1909.25V260.496H1844.77V233.109H1858.34V146.997H1845.03V119.61H1892.54Z"
                    fill="white"
                  />
                  <path
                    d="M1996.7 172.541H2057.52C2055.17 154.634 2044.47 144.89 2028.02 144.89C2012.88 144.89 2001.14 155.161 1996.7 172.541ZM2057 214.148H2093.29C2087.02 244.696 2061.7 263.656 2027.76 263.656C1988.61 263.656 1959.89 232.845 1959.89 189.658C1959.89 146.997 1988.87 115.396 2028.02 115.396C2050.21 115.396 2069.79 125.93 2081.8 144.1C2094.59 163.851 2095.12 182.548 2095.64 196.768H1994.87C1998.26 233.372 2022.02 235.216 2028.55 235.216C2042.38 235.216 2051.52 228.632 2057 214.148Z"
                    fill="white"
                  />
                  <path
                    d="M2130.62 260.496V236.532H2154.9V260.496H2130.62Z"
                    fill="white"
                  />
                  <path
                    d="M2214.94 189.921C2214.94 225.209 2230.34 246.012 2255.93 246.012C2282.55 246.012 2298.48 224.419 2298.48 188.868C2298.48 155.424 2281.77 134.093 2255.93 134.093C2230.6 134.093 2214.94 155.424 2214.94 189.921ZM2256.19 263.656C2218.07 263.656 2192.23 233.635 2192.23 189.395C2192.23 146.207 2218.6 116.45 2256.71 116.45C2294.04 116.45 2321.19 147.26 2321.19 189.395C2321.19 233.372 2294.56 263.656 2256.19 263.656Z"
                    fill="white"
                  />
                  <path
                    d="M2386.45 118.556V151.21C2393.24 136.463 2396.9 131.987 2403.16 125.93C2410.47 119.61 2419.61 116.186 2428.75 116.186C2432.14 116.186 2436.84 116.713 2443.89 117.766V138.57C2436.84 137.254 2432.66 136.727 2429.01 136.727C2390.89 136.727 2386.45 176.491 2386.45 178.598V243.906H2410.47V260.496H2346.25V243.906H2366.35V135.41H2345.47V118.556H2386.45Z"
                    fill="white"
                  />
                  <path
                    d="M2564.43 196.505V170.961C2564.43 155.424 2545.63 133.303 2524.23 133.303C2501.25 133.303 2487.16 152.264 2487.16 183.074C2487.16 214.412 2501.52 233.372 2525.53 233.372C2546.16 233.372 2564.43 213.095 2564.43 196.505ZM2564.43 255.229V225.735C2551.64 243.906 2537.54 249.962 2522.14 249.962C2488.46 249.962 2465.23 222.049 2465.23 181.758C2465.23 143.574 2487.94 116.45 2519.79 116.45C2545.63 116.45 2557.12 133.04 2564.43 143.837V118.556H2604.11V135.41H2584.53V251.806C2584.53 286.04 2562.86 305.527 2524.75 305.527C2501.52 305.527 2481.68 295.257 2472.28 278.666C2470.45 275.243 2468.88 271.82 2466.53 264.709H2489.25C2497.6 281.826 2507.52 288.147 2526.32 288.147C2550.07 288.147 2564.43 275.77 2564.43 255.229Z"
                    fill="white"
                  />
                  <path
                    d="M2677.69 21.5641H2675.48V32.7223H2682.03C2685.87 32.7223 2688.49 30.5087 2688.49 27.2561C2688.49 24.6812 2687.09 22.6031 2684.87 21.9707C2683.92 21.6996 2681.62 21.5641 2677.69 21.5641ZM2667.75 21.5641V18.4019H2682.3C2692.37 18.4019 2692.46 26.0816 2692.46 26.7592C2692.46 29.4245 2691.24 31.6833 2689.03 33.0385C2688.49 33.3999 2687.9 33.671 2686.63 34.1679C2690.47 35.3425 2691.65 36.3363 2692.06 43.3384L2692.24 47.088H2696.12V50.2502H2688.44L2688.35 46.5459C2688.12 39.6793 2687.94 35.8394 2682.48 35.8394H2675.48V47.088H2679.23V50.2502H2667.57V47.088H2671.5V21.5641H2667.75Z"
                    fill="white"
                  />
                  <circle
                    cx="2681.12"
                    cy="34.2137"
                    r="33.3812"
                    stroke="white"
                  />
                </svg>
              </a>
            </div>
            <div className={`containerFooter`}>
              <div
                className={`footerWrapper ${
                  isPayPage ? "footer-wrap-not-display " : ""
                }`}
              >
                <div className="footer-list-block">
                  <h3>Raffle House</h3>
                  <ul>
                    <li>
                      <a href={process.env.REACT_APP_BASE_URL}>Home</a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_BASE_URL + "/winners"}>
                        Our winners
                      </a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_BASE_URL + "/blog"}>
                        Blog
                      </a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_BASE_URL + "/sign-in"}>
                        Login/sign up
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-list-block">
                  <h3>Draw</h3>
                  <ul>
                    {/* <li>
                      <a href={process.env.REACT_APP_BASE_URL + '/bonus-draw'}>
                        Land Rover Bonus Draw
                      </a>
                    </li> */}
                    <li>
                      <a
                        href={process.env.REACT_APP_BASE_URL + "/subscription"}
                      >
                        Subscribe
                      </a>
                    </li>
                    {/* <li>
                      <a href={process.env.REACT_APP_BASE_URL + '/gift'}>
                        GET 10 FREE TICKETS
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="footer-list-block">
                  <h3>Legal</h3>
                  <ul>
                    <li>
                      <a href={process.env.REACT_APP_BASE_URL + "/legal/0"}>
                        Terms
                      </a>
                    </li>
                    <li>
                      <a href={process.env.REACT_APP_BASE_URL + "/legal/1"}>
                        Privacy
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="footer-list-block">
                  <h3>Connect</h3>
                  <ul>
                    <li>
                      <a href="m&#97;&#105;lt&#111;&#58;inf&#37;&#54;F&#64;%72aff&#108;%&#54;5h%6Fus&#37;6&#53;&#37;2Eco%6&#68;">
                        Contact us
                      </a>
                    </li>
                    <li>
                      <a href="m&#97;&#105;lt&#111;&#58;inf&#37;&#54;F&#64;%72aff&#108;%&#54;5h%6Fus&#37;6&#53;&#37;2Eco%6&#68;">
                        <span>
                          in&#102;&#111;&#64;raf&#102;&#108;e&#104;o&#117;se&#46;&#99;om
                        </span>
                      </a>
                    </li>
                  </ul>
                  <div className="socialIcons">
                    <div className="socialIconsImages">
                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/RaffleHouseUK/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              height="18"
                              width="18"
                              src={facebook}
                              alt="facebook"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/RaffleHouseUK/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              height="18"
                              width="18"
                              src={instagram}
                              alt="instagram"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/RaffleHouseUK/"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              height="20"
                              width="20"
                              src={twitter}
                              alt="twitter"
                            />
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.tiktok.com/@rafflehouseuk"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <img
                              height="18"
                              width="17.8"
                              src={tiktok}
                              alt="tiktok"
                            />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className='trust-wrapper'>
                    <TrustBox isHeader isDark />
                  </div> */}
                </div>
              </div>
              <div className="companyDesc">
                <h3>About Raffle House</h3>
                <span>
                  We offer you the chance to unlock your dreams, and all of our
                  competitions include a free postal entry method. Regardless of
                  the method of entry, every ticket is automatically added to
                  the relevant bonus prize pools and will be entered for each
                  related competition. No purchase is necessary to enter though
                  all online entries are able to support a charitable cause
                  close to your heart. It’s time to dream big and do good. This
                  content is not intended for anyone under 18 years of age.
                </span>
              </div>
            </div>
            <div className="footer-divider-container">
              <div className="footer-divider"></div>
            </div>
            <div className="footer-sponsors-block">
              <a href="#">{`Copyright 2017-${currentYear} - Raffle House Ltd.`}</a>
              {/* <div className='trust-wrapper'>
                <TrustBox isHeader isDark />
              </div> */}
              <a href="#">Company Reg. No. 10928240 - All Rights Reserved</a>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
}

export default Footer;
